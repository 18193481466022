import React, { useContext, useState, useEffect } from "react"
import { linkResolver } from "../prismic/linkResolver"
import Spinner from "react-spinner-material"
import Wrapper from "../components/Wrapper"
import Grid from "../components/Grid"
import StoreContext from "../context/StoreContext"
import ProjectWindow from "../components/Windows/Index"
import NewWindow from "../components/Windows/NewWindow"
import { format, parseISO, add } from "date-fns"

const Project = ({ data }) => {
  const context = useContext(StoreContext)
  const [refetch, setRefetch] = useState(0)
  const [project, setProject] = useState()
  const [isEdit, setEdit] = useState(false)
  const [projectStatus, setProjectStatus] = useState()
  const [count, setCount] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [showFloorPlan, setShowFloorPlan] = useState(false)

  let projectId
  let type
  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1))
    projectId = params.get("id") // is the string "Jonathan"
    type = params.get("type") // is the string "Jonathan"
  }

  useEffect(() => {
    async function fetchProject() {
      const response = await fetch(
        "/.netlify/functions/fetch-project?id=" + projectId,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      let project = await response.json()
      console.log("project", project)
      setProject(project)
      setProjectStatus(project.project.fields.Status)
      setLoading(false)
    }
    setLoading(true)
    fetchProject()
  }, [count, refetch])

  const installationComplete = () => {
    const data = {
      fields: {
        "Installation status": "Installation complete",
      },
    }
    let update = updateProject(data)
  }

  const createClaim = () => {
    const data = {
      fields: {
        "Installation status": "Installation complete",
        Claim: true,
        "Ordering of goods status": "Claim",
      },
    }
    let update = updateProject(data)
  }

  const measurementComplete = () => {
    const data = {
      fields: {
        "Measurement status": "Measurement completed",
      },
    }
    let update = updateProject(data)
  }

  const updateProject = async data => {
    const response = await fetch(
      "/.netlify/functions/update-project?id=" + projectId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )

    setCount(count => {
      return count + 1
    })

    return await response.json()
  }

  const handleMeasureSubmit = async event => {
    event.preventDefault()
    const windowId = event.target.window_id.value
    const height = event.target.height.value
    const width = event.target.width.value
    const note = event.target.note.value

    const data = {
      fields: {
        "Measurement height": height,
        "Measurement width": width,
        Note: note,
      },
    }

    const response = await fetch(
      "/.netlify/functions/update-window?id=" + windowId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )

    setCount(count => {
      return count + 1
    })
  }

  const toggleFloorPlan = () => {
    setShowFloorPlan(showFloorPlan => {
      return !showFloorPlan
    })
  }

  let addressLink

  if (project) {
    addressLink =
      "http://maps.apple.com/?daddr=" +
      project.project.fields["Order shipping address"]
  }

  function installationStartDate(timeString) {
    if (!timeString) return
    const date = parseISO(timeString)
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    const formattedDate = format(utcDate, "dd.MM.yy - HH:mm")
    return formattedDate
  }

  function installationEndTime(timeString) {
    if (!timeString) return
    const date = add(parseISO(timeString), {
      hours: 3,
    })

    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    const formattedDate = format(utcDate, "HH:mm")
    return formattedDate
  }

  return (
    <>
      <div
        className="co--light lh--m mb--l article project"
        style={{ paddingBottom: "80px" }}
      >
        <h1>Projekt: {project && project.project.id}</h1>
        {isLoading && (
          <div className="mb--l">
            <Spinner radius={20} color={"#333"} stroke={1} visible={true} />
          </div>
        )}
        {projectStatus === "Unfulfilled" && (
          <>
            <div className="mb--m">
              <span style={{ fontWeight: "bold" }}>Status</span> -{" "}
              {project && <>{project.project.fields["Installation status"]}</>}
            </div>
            <div
              className="button fs--s consultancy-button primary"
              onClick={installationComplete}
            >
              <div className="button-text">Montering færdig</div>
              <div className="button-icon">
                <svg className="stroke" viewBox="0 0 24 24">
                  <path d="M9 18l6-6-6-6"></path>
                </svg>
              </div>
            </div>
            <br />
            <div
              className="button fs--s consultancy-button primary"
              onClick={createClaim}
            >
              <div className="button-text">Opret claim</div>
              <div className="button-icon">
                <svg className="stroke" viewBox="0 0 24 24">
                  <path d="M9 18l6-6-6-6"></path>
                </svg>
              </div>
            </div>
          </>
        )}
        {projectStatus === "Awaiting offer" && (
          <>
            <div className="mb--m">
              <span style={{ fontWeight: "bold" }}>Status</span> -{" "}
              {project && <>{project.project.fields["Measurement status"]}</>}
            </div>
            <div
              className="button fs--s consultancy-button primary"
              onClick={measurementComplete}
            >
              <div className="button-text">Opmåling færdig</div>
              <div className="button-icon">
                <svg className="stroke" viewBox="0 0 24 24">
                  <path d="M9 18l6-6-6-6"></path>
                </svg>
              </div>
            </div>
          </>
        )}
        {project && (
          <div className="card mb--m" style={{ border: "1px solid #555" }}>
            {project.project.fields["Order customer name"] && (
              <>
                <h2
                  className="fs--m"
                  style={{ fontSize: 25, fontWeight: "300" }}
                >
                  Kunde
                </h2>
                <span style={{ fontWeight: "bold" }}>Navn</span> -{" "}
                {project.project.fields["Order customer name"]}
                <br />
              </>
            )}
            <span style={{ fontWeight: "bold" }}>
              Skinne og gardin id
            </span> - {project.project.fields["Pagunette order number"]}
            <br />
            <span style={{ fontWeight: "bold" }}>Email</span> -{" "}
            {project.project.fields["Email"]}
            <br />
            <span style={{ fontWeight: "bold" }}>Telefon</span> -{" "}
            {project.project.fields["Phone number"]}
            <br />
            <span style={{ fontWeight: "bold" }}>Adresse</span> -{" "}
            <a href={addressLink}>
              {project.project.fields["Order shipping address"]}
            </a>
            <br />
            <br />
            {project.project.fields["Delivery date"] && (
              <div>
                <span style={{ fontWeight: "bold" }}>Installation date</span> -{" "}
                {project.project.fields["Delivery date"] &&
                  installationStartDate(
                    project.project.fields["Delivery date"]
                  )}
                -
                {project.project.fields["Delivery date"] &&
                  installationEndTime(project.project.fields["Delivery date"])}
              </div>
            )}
            <br />
            <span style={{ fontWeight: "bold" }}>Location of rails</span> -{" "}
            {project.project.fields["Location of rails"]}
            <br />
            <span style={{ fontWeight: "bold" }}>
              Location of curtains
            </span> - {project.project.fields["Location of curtains"]}
            <br />
            {project && project.project.fields["Floor plan"] && (
              <div>
                <div onClick={() => toggleFloorPlan()}>
                  <span style={{ fontWeight: "bold" }}>Plantegning</span> - Se
                  plantegning
                </div>
                <div style={{ display: showFloorPlan ? "block" : "none" }}>
                  {project.project.fields["Floor plan"].map((image, index) => {
                    return (
                      <a href={image.url} target="_blank">
                        <img src={image.url} />
                      </a>
                    )
                  })}
                </div>
              </div>
            )}
            <div>
              <br />
              <span style={{ fontWeight: "bold" }}>Responsible</span> -
              {project.project.fields["User"]?.name}
            </div>
            {project.project.fields["Montør note"] && (
              <div>
                <br />
                <span style={{ fontWeight: "bold" }}>Installation note:</span>
                <br />
                {project.project.fields["Montør note"]}
              </div>
            )}
          </div>
        )}
        <hr style={{ margin: "40px 0 40px" }} />

        <NewWindow setCount={setCount} count={count} projectId={projectId} />
        {project &&
          project.windows.records.map((window, index) => {
            return (
              <ProjectWindow
                window={window}
                key={window.id}
                index={index}
                internal={true}
                refetch={refetch}
                setRefetch={setRefetch}
              />
            )
          })}
      </div>
    </>
  )
}

export default Project
